import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    /**
     * hydrate the state from the session if it exists otherwise null it
     */
    firstName: null,
    lastName: null,
    email: null,
    roles: [],
    permissions: [],
    isTwoFactorActive: false,
    isOrgUnitAdmin: false,
  }),

  getters: {
    isLoggedIn: (state) => {
      return state.email !== null;
    },
  },

  actions: {
    /**
     * Remove the login information and effectively log the user out
     * @param {object} state Module state
     */
    removeAuth() {
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.isTwoFactorActive = false;
      this.roles = [];
      this.permissions = [];
      this.isOrgUnitAdmin = false;
    },

    setUser(user) {
      this.email = user.email;
      this.roles = user.roles;
      this.permissions = user.permissions;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.isTwoFactorActive = user.isTwoFactorActive;
      this.isOrgUnitAdmin = user.isOrgUnitAdmin;
    },
  },
});
