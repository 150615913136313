import i18n from '@/i18n';
import router from '@/router';

/**
 * Sets the i18n language to the given language
 * @param {string} language The new language
 */
const setLanguage = (language) => {
  localStorage.setItem('lang', language);
  const currentRoute = router.currentRoute;
  if (currentRoute.name === 'documentDetail') {
    if (currentRoute.query.lang !== language) {
      router.push({ ...currentRoute, query: { lang: language } });
    }
  }
  i18n.locale = language;
  i18n.global.locale.value = language;
  document.documentElement.setAttribute('lang', language);
};

const loadLanguage = () => {
  if (localStorage.getItem('lang')) {
    i18n.locale = localStorage.getItem('lang');
  }
};

export { setLanguage, loadLanguage };
