<template>
  <div id="app" class="app-master">
    <router-view />
    <notification />
  </div>
</template>

<script setup>
import { onBeforeMount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import Notification from '@/components/Notification.vue';

import { setLanguage } from '@/services/language.service';

const route = useRoute();
const { locale } = useI18n()

const LANGUAGES = ['de', 'fr', 'it'];

watch(route, (to) => {
  document.title = to.meta.title || 'asaWebLex';
});

onBeforeMount(() => {
  const savedLanguage = localStorage.getItem('lang');
  // Get default browser language code.
  let browserLanguage = navigator.language.split('-')[0];

  // If no language is saved in the localstorage and the default browser language is not the same as the app language,
  // Change it to the browser language.
  if (!localStorage.getItem('lang') && browserLanguage !== locale.value) {
    if (!LANGUAGES.includes(browserLanguage)) {
      browserLanguage = 'de';
    }

    setLanguage(browserLanguage);
  } else if (savedLanguage !== locale.value && savedLanguage !== null) {
    setLanguage(savedLanguage);
  } else {
    document.documentElement.setAttribute('lang', locale.value);
  }
});
</script>

<style lang="postcss">
.app-master {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: #f7f7f7;
}

#app {
  height: 100%;
}
</style>
