import { userApi } from './api';
import { getSortState } from './helpers';
import i18n from '../i18n';
import { createTreeFromList } from '../helpers/blockList';

const buildParams = (queryParams, page, searchTerm) => {
  let query = '?';
  if (queryParams) {
    const params = [];
    params['lang'] = i18n.locale;
    Object.keys(queryParams).forEach((key) => {
      if (Array.isArray(queryParams[key])) {
        queryParams[key].forEach((filter) => {
          params.push(`${key}=${filter}`);
        });
      } else {
        params.push(`${key}=${queryParams[key]}`);
      }
    });
    query += params.join('&');
  }
  if (page) {
    query += `&page=${page}`;
  }
  if (searchTerm) {
    query += `&search=${searchTerm}`;
  }
  return query;
};

export const getDocumentsData = async (queryParams, page, searchTerm) => {
  const query = buildParams(queryParams, page, searchTerm);
  const response = await userApi.get('document/' + query);
  return response.data;
};

export const getAllContent = async (queryParams, page, searchTerm) => {
  const query = buildParams(queryParams, null, searchTerm);
  const response = await userApi.get('document/all-content/' + query);
  return response.data;
};

export const getDocumentById = async (
  id,
  payload,
  loadFallback,
  loadNewest,
  isUserSection
) => {
  let url = `document/${id}/`;

  const params = [];

  if (loadFallback) {
    params.push('load_fallback=true');
  }
  if (loadNewest) {
    params.push('load_newest=true');
  }
  if (isUserSection) {
    params.push('user=true');
  }
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }
  const response = await userApi.get(url, {
    params: payload,
  });
  response.data.block = createTreeFromList(response.data.block, 'parent');
  return response.data;
};

export const postDocument = async (payload) => {
  const fd = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value) fd.append(key, value);
    else fd.append(key, '');
  });
  const response = await userApi.post('document/', fd);
  return response.data;
};

export const updateDocument = async (documentId, payload) => {
  const fd = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value) fd.append(key, value);
    else fd.append(key, '');
  });
  const response = await userApi.put(`document/${documentId}/`, fd);
};

export const getAllDocuments = async () => {
  const response = await userApi.get('document/?no_pagination=true');
  return response.data;
};

export const copyDocument = async (id) => {
  const response = await userApi.post(`document/${id}/version/new/`);
  return response.data;
};

export const uploadNewPdf = async (id, payload) => {
  const fd = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value) fd.append(key, value);
    else fd.append(key, '');
  });
  const response = await userApi.post(`document/${id}/version/add/`, fd);
  return response.data;
};

export const addNewLanguage = async (id, payload) => {
  const fd = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value) fd.append(key, value);
    else fd.append(key, '');
  });
  const response = await userApi.post(`document/${id}/language/add/`, fd);
  return response.data;
};

export const publishDocument = async (id, payload) => {
  const response = await userApi.post(
    `document/${id}/version/publish/`,
    payload
  );
  return response.data;
};

// Document related objects //
export const getTypesOfLaw = async () => {
  const response = await userApi.get('type-of-law/');
  return response.data;
};

export const getOriginsOfLaw = async () => {
  const response = await userApi.get('origin-of-law/');
  return response.data;
};

export const addLink = async (link) => {
  const response = await userApi.post(`link/`, link);
  return response.data;
};

export const updateLink = async (link) => {
  const response = await userApi.put(`link/${link.id}/`, link);
  return response.data;
};

export const moveLink = async (link, data) => {
  const response = await userApi.post(`link/${link.id}/move/`, data);
  return response.data;
};

export const saveBlock = async (block) => {
  const response = await userApi.put(`block/${block.id}/`, block);
  return response.data;
};

export const deleteLink = async (linkId) => {
  const response = await userApi.delete(`link/${linkId}/`);
  return response.data;
};

export const deleteBlock = async (blockId) => {
  const response = await userApi.delete(`block/${blockId}/`);
  return response.data;
};

export const softDeleteBlock = async (blockId) => {
  const response = await userApi.patch(`block/${blockId}/soft_delete/`);
  return response.data;
};

export const restoreBlock = async (blockId) => {
  const response = await userApi.patch(`block/${blockId}/restore/`);
  return response.data;
};

export const getCreators = async () => {
  const response = await userApi.get('creator/');
  return response.data;
};

export const deletePdf = async (documentId, lang) => {
  const response = await userApi.post(`document/${documentId}/delete/pdf/`, {
    language: lang,
  });
};

export const getCategories = async () => {
  const response = await userApi.get('category/');
  return response.data;
};

export const searchDocumentWithNoPagination = async (searchTerm) => {
  let query = `?noPagination&search=${searchTerm}`;
  const response = await userApi.get('document/' + query);
  return response.data;
};

export const deleteDocument = async (id) => {
  const response = await userApi.delete(`document/${id}/`);
  return response.data;
};

export const deleteLanguage = async (id, lang) => {
  await userApi.delete(`document/${id}/language/delete`, {
    data: { language: lang },
  });
};

export const getMissingPdfs = async (id) => {
  const response = await userApi.get(`document/${id}/version/missing-pdfs/`);
  return response.data;
};

export const getDocumentLinks = async (id, lang, loadFallback, in_place_only=false) => {
  const params = { lang };

  if (loadFallback) {
    params['load_fallback'] = true;
  }
  if (in_place_only) {
    params['in_place_only'] = true;
  }

  const response = await userApi.get(`document/${id}/links/`, { params });
  return response.data;
};

export const updateDocumentDescription = async (id, payload) => {
  const response = await userApi.put(
    `document/${id}/update/description/`,
    payload
  );
  return response.data;
};

export const getLink = async (id) => {
  const response = await userApi.get(`link/${id}`);
  return response.data;
};
