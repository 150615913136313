import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../store/Auth.js';
import { useBlockStore } from '../store/Block.js';
import { useFavoriteStore } from '../store/Favorite.js';
import routes from './routes.js';
import { getCurrentUser } from '@/services/auth';
import { getLink } from '@/services/document';

let initial = true;

const router = createRouter({
  history: typeof window !== 'undefined'
      ? createWebHistory(import.meta.env.BASE_URL)
      : 'http://forge.docker.test',
  routes,
});

/**
 * Check if the user is logged in for restricted views
 *
 * @param {object} to The route to navigate to
 */
const checkUserLoggedIn = (to) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.isLoggedIn) {
    return false;
  }
  return true;
};

/**
 * Check if the user hat sufficient rights to access view
 *
 * @param {object} to The route to navigate to
 */
const checkUserIsPermitted = (to) => {
  const authStore = useAuthStore();
  if (
    to.meta.requiredPermission &&
    !authStore.permissions.includes(to.meta.requiredPermission)
  ) {
    return false;
  }
  if (to.meta.requiresOrgUnitAdmin && !authStore.isOrgUnitAdmin) {
    return false;
  }
  if (
    to.meta.requiredRoles &&
    !authStore.roles.some((role) => to.meta.requiredRoles.includes(role))
  ) {
    return false;
  }
  return true;
};

router.beforeEach(async (to, from, next) => {
  const goToPage = (routeName, params = {}) => {
    return next({ name: routeName, params });
  };

  const authStore = useAuthStore();
  const favoriteStore = useFavoriteStore();
  const blockStore = useBlockStore();

  // as there are views e.g. the dashboard which are available for logged-in
  // and logged-out users, we need to set the user also in this cases
  if (initial) {
    initial = false;
    try {
      const user = await getCurrentUser();
      authStore.setUser(user.data);
      await blockStore.loadCreators();
      await blockStore.loadTypesOfLaw();
      await favoriteStore.loadFavorites();
    } catch {}
  }
  // Check whether the user is logged in to access restricted views
  if (!checkUserLoggedIn(to)) {
    return goToPage('login');
  }

  // Check whether the user has sufficient rights to access a view
  if (!checkUserIsPermitted(to)) {
    // If the route has a specific redirect view, redirect the user to this view
    if (to.meta.noPermissionRedirectTo) {
      return goToPage(to.meta.noPermissionRedirectTo);
    }
    return goToPage('dashboard');
  }

  if (to.name === 'link') {
    const link = await getLink(to.params.id);

    if (!link) {
      return goToPage('allContent');
    }

    if (link.linkedDocument) {
      return goToPage('documentDetail', { id: link.linkedDocument.id });
    }

    return goToPage('documentDetail', {
      id: link.destination.documentId,
      blockId: link.destination.id,
    });
  }

  return next();
});

export default router;
