import { getFavorites } from '@/services/Favorite.service';
import { defineStore } from 'pinia';

export const useFavoriteStore = defineStore('favorite', {
  state: () => ({
    favorites: {},
  }),

  actions: {
    setFavorites(favorites) {
      this.favorites = favorites;
    },

    async loadFavorites() {
      const languages = ['de', 'fr', 'it', 'en'];
      const promises = [];

      languages.forEach((language) => {
        promises.push(getFavorites(language));
      });

      const responses = await Promise.allSettled(promises);

      const favorites = {};

      responses.forEach((response, index) => {
        favorites[languages[index]] = response.value;
      });

      this.setFavorites(favorites);
    },
  },
});
