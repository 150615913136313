import { getCreators, getTypesOfLaw } from '@/services/document';
import { defineStore } from 'pinia';

export const useBlockStore = defineStore('block', {
  state: () => ({
    elementReferences: [],
    categories: [],
    creators: [],
    typesOfLaw: [],
  }),

  actions: {
    setElementReferences(elementReferences) {
      this.elementReferences = elementReferences;
    },
    setCategories(categories) {
      this.categories = categories;
    },
    setCreators(creators) {
      this.creators = creators;
    },
    setTypesOfLaw(typesOfLaw) {
      this.typesOfLaw = typesOfLaw;
    },

    async loadCreators() {
      const creators = await getCreators();
      this.setCreators(creators);
    },
    async loadTypesOfLaw() {
      const typesOfLaw = await getTypesOfLaw();
      this.setTypesOfLaw(typesOfLaw);
    },
  },
});
