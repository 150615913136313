import { createApp } from 'vue';
import App from './App.vue';
import router from './router/';
import i18n from './i18n';
import { createPinia } from 'pinia';
import { floatingVueOptions } from "./plugins/floatingVue";

// Directives
import { directives } from './directives';

// Styles
import './assets/css/main.postcss';
import 'floating-vue/dist/style.css'

// Plugins

/* 
import './plugins/vuelidate';
import './plugins/notifications';
import './plugins/fragment';
import './plugins/lineClamp';
import './plugins/dialog'; */
import Notifications from "@kyvg/vue3-notification";

// Global components
import SvgIcon from './components/SvgIcon.vue';
import Multiselect from 'vue-multiselect';
import FloatingVue from "floating-vue";

let app = null;
const setupApp = (app) => {
  app.config.productionTip = false;
  app.use(router);
  app.use(i18n);
  app.use(createPinia());

  app.use(Notifications);

  app.use(FloatingVue, floatingVueOptions);

  app.directive('loading', directives.loading);
  app.directive('focus', directives.focus);

  // Global components
  app.component('SvgIcon', SvgIcon);
  app.component('multiselect', Multiselect);
  
  app.mount('#app');
};

app = createApp(App);
setupApp(app);
